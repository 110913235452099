import { Injectable } from '@angular/core';
import {
    APPOINTMENT_UNSCHEDULED,
    AppointmentDateDto,
    AppointmentDateType,
    AppointmentDateTypes,
    AppointmentDocumentDto,
    AppointmentProductDto,
    JobDatesProvider,
    OrderAppointmentDto,
    OrderDto,
} from '@domain/appointment';
import {
    AppointmentActionRequired,
    AppointmentCustomerDTO,
    AppointmentDate,
    AppointmentType,
    Order,
    OrderAppointment,
} from '@domain/database';
import { getUnixTime } from 'date-fns';
import { ArrayHelper } from '@shared/utils';

@Injectable()
export class DataHelperService {
    constructor(private jobDatesProvider: JobDatesProvider) {}

    public orderDTOtoOrderAppointmentMap(
        orderDetails: Partial<OrderDto>,
        appointment: OrderAppointmentDto
    ): OrderAppointment {
        const appointmentDates = this.getOrderAppointmentDates(appointment.dates);
        return {
            id: `${orderDetails.retailOrderId}_${appointment.appointmentType}_${orderDetails.environment}`,
            orderId: `${orderDetails.retailOrderId}_${orderDetails.environment}`,
            appointmentType: appointment.appointmentType,
            status: appointment.status,
            dates: appointmentDates,
            filterableDate: this.getFilterableDate(appointment.appointmentType, appointmentDates),
            roNumber: orderDetails.roNumber,
            dealerId: `${orderDetails.dealerId}_${orderDetails.environment}`,
            customer: this.customerDataMap(orderDetails.customer),
            // customer: {
            //     ...orderDetails.customer,
            // },
            actionsRequired: orderDetails.actionsRequired
                ? orderDetails.actionsRequired.map((ar: AppointmentActionRequired) => {
                      return {
                          id: ar.id,
                          description: ar.description,
                          isOptional: ar.isOptional,
                      };
                  })
                : [],
        };
    }

    public orderDTOToOrderMap(orderDetails: OrderDto): Order {
        return {
            id: `${orderDetails.retailOrderId}_${orderDetails.environment}`,
            productLineId: orderDetails.productLineId,
            roTypeId: orderDetails.roTypeId,
            originatingRO: orderDetails.originatingRO,
            description: orderDetails.description,
            shipToName: orderDetails.shipToName,
            shipToAddress1: orderDetails.shipToAddress1,
            shipToAddress2: orderDetails.shipToAddress2,
            shipToCity: orderDetails.shipToCity,
            shipToState: orderDetails.shipToState,
            shipToZip: orderDetails.shipToZip,
            projectNumber: orderDetails.projectNumber,
            storeNumber: orderDetails.storeNumber,
            salesAssocName: orderDetails.salesAssocName,
            isVanityOrder: orderDetails.isVanityOrder,
            kindOfOrder: orderDetails.kindOfOrder,
            packingItemModels: orderDetails.packingItemModels,
            environment: orderDetails.environment,
            retailOrderId: orderDetails.retailOrderId,
            roNumber: orderDetails.roNumber,
            status: orderDetails.orderStatus,
            dealerId: `${orderDetails.dealerId}_${orderDetails.environment}`,
            proxyDealerId: `${orderDetails.proxyDealerId}_${orderDetails.environment}`,
            modifiedUnix: getUnixTime(new Date(orderDetails.modified)),
            roDateUnix: getUnixTime(new Date(orderDetails.roDate)),
            roDate: orderDetails.roDate,
            cabinetsPurchased: orderDetails.cabinetsPurchased,
            isDelivery: orderDetails.isDelivery,
            checklistAvailable: orderDetails.checklistAvailable,
            isCabinetInstallIncomplete: orderDetails.isCabinetInstallIncomplete,
            cabinetInstallIncompleteReason: orderDetails.cabinetInstallIncompleteReason,
            customer: this.customerDataMap(orderDetails.customer),
            //     {
            //     ...orderDetails.customer,
            // },
            // some appointments are unscheduled, so we decided to use a specific value to diferentiate them
            // can't use null as value because in that case we can't index this column in RxDB
            products: orderDetails.products
                ? orderDetails.products.map((product: AppointmentProductDto) => {
                      return {
                          roProductId: product.roProductId,
                          productId: product.productId,
                          quantity: product.quantity,
                          uom: product.UOM ? product.UOM : undefined,
                          sku: product.SKU ? product.SKU : undefined,
                          description: product.description ? product.description : undefined,
                          productType: product.productType,
                          productLine: product.productLine ? product.productLine : undefined,
                          thickness: product.thickness ? product.thickness : undefined,
                          price: product.price ? product.price : undefined,
                          extendedPrice: product.extendedPrice ? product.extendedPrice : undefined,
                          thumbnailUrl: product.thumbnailUrl ? product.thumbnailUrl : undefined,
                          MFRModel: product.MFRModel ? product.MFRModel : undefined,
                      };
                  })
                : [],
            notes: orderDetails.notes
                ? orderDetails.notes.map((note) => {
                      return {
                          dateUnix: getUnixTime(new Date(note.date)),
                          id: note.id,
                          text: note.text ? note.text : undefined,
                          userName: note.userName ? note.userName : undefined,
                          noteTypeId: note.noteTypeId,
                      };
                  })
                : [],
            documents: orderDetails.documents
                ? orderDetails.documents.map((doc: AppointmentDocumentDto) => {
                      return {
                          url: doc.url,
                          fileName: doc.fileName,
                          dateUnix: getUnixTime(new Date(doc.date)),
                          documentType: doc.documentType,
                          thumbnailUrl: doc.thumbnailUrl ? doc.thumbnailUrl : undefined,
                          documentId: doc.documentId,
                      };
                  })
                : [],
            isChangeNoteAllowed: orderDetails.isChangeNoteAllowed,
            // todo: remove actionRequired from order
            actionsRequired: orderDetails.actionsRequired
                ? orderDetails.actionsRequired.map((ar: AppointmentActionRequired) => {
                      return {
                          id: ar.id,
                          description: ar.description ? ar.description : undefined,
                          isOptional: ar.isOptional,
                      };
                  })
                : [],
        };
    }

    // in order to simplify and speed up the query we construct a new field upfront
    public getFilterableDate(appointmentType: AppointmentType, appointmentDates: AppointmentDate[]): number {
        const appointmentCompleteDate = this.jobDatesProvider.getAppointmentDate(
            appointmentType,
            appointmentDates,
            AppointmentDateTypes.APPOINTMENT_COMPLETE_DATE
        );

        if (appointmentCompleteDate) {
            return appointmentCompleteDate;
        }

        const appointmentDate = this.jobDatesProvider.getAppointmentDate(
            appointmentType,
            appointmentDates,
            AppointmentDateTypes.APPOINTMENT_DATE
        );

        return appointmentDate ? appointmentDate : APPOINTMENT_UNSCHEDULED;
    }

    // we will filter either by scheduled or actual date.

    private getOrderAppointmentDates(dates: AppointmentDateDto[]): AppointmentDate[] {
        const appointmentDates: AppointmentDate[] = dates
            ? dates.map((dateDetails: AppointmentDateDto) => {
                  // TODO This is a hack to bypass timezone data that is returned from the API
                  // console.log('the date is', dateDetails.date ? dateDetails.date : 'null');
                  return {
                      dateType: dateDetails.dateType,
                      dateUnix: dateDetails.date ? getUnixTime(new Date(dateDetails.date.substring(0, 19))) : undefined,
                      isEditable: dateDetails.isEditable,
                  };
              })
            : [];

        // move cabinet date to the end of array
        const cabinetDateIndex = appointmentDates.findIndex(
            (dateDetails: AppointmentDateDto) => dateDetails.dateType === AppointmentDateType.CabinetDate
        );
        ArrayHelper.arrayMoveItem<AppointmentDate>(appointmentDates, cabinetDateIndex, appointmentDates.length - 1);

        return appointmentDates;
    }

    private customerDataMap(item: AppointmentCustomerDTO): AppointmentCustomerDTO {
        return {
            name: item.name || undefined,
            address1: item.address1 || undefined,
            address2: item.address2 || undefined,
            city: item.city || undefined,
            state: item.state || undefined,
            zip: item.zip || undefined,
            countryCode: item.countryCode || undefined,
            phone: item.phone || undefined,
            email: item.email || undefined,
        };
    }
}
