import { EnvironmentType } from '../../database/models';

export class AppointmentSaveDateDto {
    public dateType: number;
    public date: string;
    public environment: EnvironmentType;
    public retailOrderId: number;
    public isCabinetInstallIncomplete?: boolean;
    public cabinetInstallIncompleteReason?: number;

    constructor(fields: Partial<AppointmentSaveDateDto>) {
        this.dateType = fields.dateType;
        this.date = fields.date;
        this.environment = fields.environment;
        this.retailOrderId = fields.retailOrderId;
        this.isCabinetInstallIncomplete = fields.isCabinetInstallIncomplete;
        this.cabinetInstallIncompleteReason = fields.cabinetInstallIncompleteReason;
    }
}
