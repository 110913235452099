import { AppointmentDocumentType } from '@domain/appointment';

const keyPrefix = 'appointments.details.files';

enum AppointmentDocumentTypeStringKey {
    Drawing = 'drawings',
    Photo = 'photos',
    EstimateDrawing = 'estimateDrawings',
    PostTemplateDrawing = 'postTemplateDrawings',
    CabinetMeasureWorksheet = 'cabinetmeasureworksheet',
    CabinetQualityCheckWorksheet = 'cabinetqualitycheckworksheet',
    AOCCOC = 'documents',
    CEA = 'documents',
    OrderDetailsConfirmation = 'documents',
    UnsignedCOC = 'documents',
}

// builds a path to appointment detail files i18n items based on a given documentType
export function keyFromDocumentType(documentType: AppointmentDocumentType, suffix?: string | undefined): string {
    const typeKey = AppointmentDocumentTypeStringKey[AppointmentDocumentType[documentType]] || 'documents';
    return suffix ? [keyPrefix, typeKey, suffix].join('.') : [keyPrefix, typeKey].join('.');
}
