import { Injectable } from '@angular/core';
import { ApiVersions, HttpService } from '@shared/http';
import { Observable } from 'rxjs';
import { Lookup } from '../../database/models/lookup.model';

@Injectable()
export class LookupService {
    private endpoint = `api/${ApiVersions.V3}/lookup`;

    constructor(private httpService: HttpService) {}

    public getList(lookupType: string): Observable<Lookup[]> {
        return this.httpService.makeGet<Lookup[]>(`${this.endpoint}/list?lookupType=${lookupType}`);
    }
}
