export enum AppointmentDocumentType {
    Drawing = 60,
    Photo = 62,
    EstimateDrawing = 66,
    PostTemplateDrawing = 67,
    AOCCOCPOD = 70,
    CEA = 79,
    CabinetMeasureWorksheet = 96,
    CabinetDesignPacket = 97,
    CabinetQualityCheckWorksheet = 98,
    OrderDetailsConfirmation = 100,
    UnsignedCOC = 107,
    WOC = 110,
    UnsignedWOC = 113,
    PostTemplateCheckList = 115,
    PostInstallCheckList = 116,
}

// This is the list of DocumentTypes that will be shown in the Files tab, sorted appropriately
export const FILES_TAB_VISIBLE_DOCUMENT_TYPES: Array<AppointmentDocumentType> = [
    AppointmentDocumentType.Photo,
    AppointmentDocumentType.Drawing,
    AppointmentDocumentType.EstimateDrawing,
    AppointmentDocumentType.PostTemplateDrawing,
    AppointmentDocumentType.AOCCOCPOD,
    AppointmentDocumentType.CabinetDesignPacket,
    AppointmentDocumentType.UnsignedCOC,
    AppointmentDocumentType.PostTemplateCheckList,
    AppointmentDocumentType.PostInstallCheckList,
    AppointmentDocumentType.WOC,
    AppointmentDocumentType.UnsignedWOC,
];

export const FILES_CABINET_MEAURE_TAB_VISIBLE_DOCUMENT_TYPES: Array<AppointmentDocumentType> = [
    ...FILES_TAB_VISIBLE_DOCUMENT_TYPES,
    AppointmentDocumentType.CabinetMeasureWorksheet,
];

export const FILES_CABINET_INSTALL_TAB_VISIBLE_DOCUMENT_TYPES: Array<AppointmentDocumentType> = [
    ...FILES_TAB_VISIBLE_DOCUMENT_TYPES,
    AppointmentDocumentType.CabinetQualityCheckWorksheet,
];
