import { ModuleWithProviders, NgModule } from '@angular/core';
import { AppointmentAocApi } from './aoc-agreement';
import { AppointmentCieApi } from './cie-agreement';
import { AppointmentCocApi } from './coc-agreement';
import { AppointmentWocApi } from './woc-agreement';
import { OrderNotesApi } from './order-notes';
import {
    AocAgreementService,
    CieAgreementService,
    CocAgreementService,
    InstallChecklistService,
    PostTemplateChecklistService,
    ImageService,
    JobDatesProvider,
    OrderService,
    LookupService,
    RetailerApi,
} from './services';
import { PackingSlipApi } from './packing-slip';
import { AppointmentIcApi } from './install-checklist';
import { AppointmentPtcApi } from './post-template-checklist';
import { AppointmentCmwApi } from './cabinet-measurement-worksheet';
import { AppointmentCqcApi } from './cabinet-quality-check';
import { FileService } from './services/file.service';

@NgModule({
    declarations: [],
    imports: [],
    exports: [],
    providers: [],
})
export class DomainAppointmentDomainModule {
    public static forRoot(): ModuleWithProviders<DomainAppointmentDomainModule> {
        return {
            ngModule: DomainAppointmentDomainModule,
            providers: [
                RetailerApi,
                AppointmentCieApi,
                CieAgreementService,
                OrderNotesApi,
                AppointmentAocApi,
                AppointmentIcApi,
                AppointmentPtcApi,
                AppointmentWocApi,
                AppointmentCmwApi,
                AppointmentCqcApi,
                AocAgreementService,
                InstallChecklistService,
                PostTemplateChecklistService,
                AppointmentCocApi,
                CocAgreementService,
                ImageService,
                FileService,
                JobDatesProvider,
                OrderService,
                LookupService,
                PackingSlipApi,
            ],
        };
    }
}
