import { EnvironmentType } from '@domain/database';

export class CabinetQualityCheckDto {
    deliveredProductYes: boolean;
    deliveredProductNo: boolean;
    cabinetsNumber: string;
    cabinetsMatchYes: boolean;
    cabinetsMatchNo: boolean;
    cabinetsCheckedYes: boolean;
    cabinetsCheckedNo: boolean;
    damageFreeYes: boolean;
    damageFreeNo: boolean;
    notes: string;
    approvedYes: boolean;
    approvedNo: boolean;
    rejectedReason: string;
    public environment: EnvironmentType;
    public retailOrderId: number;

    constructor(fields: Partial<CabinetQualityCheckDto>) {
        this.deliveredProductYes = fields.deliveredProductYes;
        this.deliveredProductNo = fields.deliveredProductNo;
        this.cabinetsNumber = fields.cabinetsNumber;
        this.cabinetsMatchYes = fields.cabinetsMatchYes;
        this.cabinetsMatchNo = fields.cabinetsMatchNo;
        this.cabinetsCheckedYes = fields.cabinetsCheckedYes;
        this.cabinetsCheckedNo = fields.cabinetsCheckedNo;
        this.damageFreeYes = fields.damageFreeYes;
        this.damageFreeNo = fields.damageFreeNo;
        this.notes = fields.notes;
        this.approvedYes = fields.approvedYes;
        this.approvedNo = fields.approvedNo;
        this.rejectedReason = fields.rejectedReason;
        this.environment = fields.environment;
        this.retailOrderId = fields.retailOrderId;
    }
}
