import { Injectable } from '@angular/core';
import { ApiVersions, HttpService } from '@shared/http';
import { CabinetQualityCheckDto } from './cabinet-quality-check.dto';
import { Observable } from 'rxjs';
import { OrderDto } from '../models';

@Injectable()
export class AppointmentCqcApi {
    private appointmentsEndpoint = `api/${ApiVersions.V3}/orders`;

    constructor(private httpService: HttpService) {}

    public submitCabinetQualityCheck$(
        environment: string,
        retailerOrderId: number,
        checklist: CabinetQualityCheckDto
    ): Observable<Partial<OrderDto>> {
        return this.httpService.makePost(
            `${this.appointmentsEndpoint}/${environment}/${retailerOrderId}/cqc`,
            checklist
        );
    }
}
