import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AocAgreementDto } from '../models/aoc-agreement.dto';

@Injectable()
export class AocAgreementService {
    private urlPath = 'assets/json/aoc_agreement.json';
    private cabinetUrlPath = 'assets/json/cabinet_aoc_agreement.json';

    constructor(private http: HttpClient) {}

    public getAocAgreement$(): Observable<AocAgreementDto> {
        return this.http.get<AocAgreementDto>(this.urlPath);
    }

    public getCabinetAocAgreement$(): Observable<AocAgreementDto> {
        return this.http.get<AocAgreementDto>(this.cabinetUrlPath);
    }
}
