import { EnvironmentType } from '@domain/database';

export class CabinetMeasurementWorksheetDto {
    public canvasImage: string;
    public ceilingHeight: string;
    public cabinetsExtendYes: boolean;
    public cabinetsExtendNo: boolean;
    public peninsulaYes: boolean;
    public peninsulaNo: boolean;
    public islandYes: boolean;
    public islandNo: boolean;
    public notes: string;
    public microwave: string;
    public range: string;
    public wallOven: string;
    public refrigerator: string;
    public cooktop: string;
    public ventHood: string;
    public dishwasher: string;
    public sink: string;
    public environment: EnvironmentType;
    public retailOrderId: number;

    constructor(fields: Partial<CabinetMeasurementWorksheetDto>) {
        this.canvasImage = fields.canvasImage;
        this.ceilingHeight = fields.ceilingHeight;
        this.cabinetsExtendYes = fields.cabinetsExtendYes;
        this.cabinetsExtendNo = fields.cabinetsExtendNo;
        this.peninsulaYes = fields.peninsulaYes;
        this.peninsulaNo = fields.peninsulaNo;
        this.islandYes = fields.islandYes;
        this.islandNo = fields.islandNo;
        this.notes = fields.notes;
        this.microwave = fields.microwave;
        this.range = fields.range;
        this.wallOven = fields.wallOven;
        this.refrigerator = fields.refrigerator;
        this.cooktop = fields.cooktop;
        this.ventHood = fields.ventHood;
        this.dishwasher = fields.dishwasher;
        this.sink = fields.sink;
        this.environment = fields.environment;
        this.retailOrderId = fields.retailOrderId;
    }
}
